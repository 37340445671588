import { User } from "api-client";
import LogRocket from "logrocket";
import { useEffect } from "react";

import useConfig from "./useConfig";

import { logrocketExposeSession } from "logrocket-expose-session";

export interface UseLogRocketOptions {
  currentUser?: User;
  enabled?: boolean;
}

export function useLogRocket(options: UseLogRocketOptions): void {
  const config = useConfig();

  useEffect(() => {
    if (!config || !config.LOGROCKET_API_KEY) {
      return;
    }

    LogRocket.init(config.LOGROCKET_API_KEY, {
      mergeIframes: true,
    });
    logrocketExposeSession(LogRocket);
  }, [config]);

  useEffect(() => {
    if (
      !options.enabled ||
      !options.currentUser ||
      !config ||
      !config.LOGROCKET_API_KEY
    ) {
      return;
    }

    const { id, firstName, lastName } = options.currentUser;
    LogRocket.identify(id, { name: `${firstName} ${lastName}` });
  }, [config, options.currentUser, options.enabled]);
}
