import { Box, Heading, Text } from "design-system";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import Link from "next/link";
import { getExhibitKeys } from "@treasured/client";

export const getStaticProps: GetStaticProps<{ slugs: string[] }> = async () => {
  const slugs = await getExhibitKeys();

  return {
    props: {
      slugs,
    },
    revalidate: 10,
  };
};

export default function Home({
  slugs,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <Box p={4}>
      <Heading as="h1" mb={4}>
        Scenes
      </Heading>
      <Box as="ul" pl={6}>
        {slugs.map((slug) => (
          <Box as="li" key={slug}>
            <Link href={`/player/${slug}`} passHref>
              <Text as="a" textDecoration="underline" color="blue.600">
                {slug}
              </Text>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
