import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const buildRoot =
  publicRuntimeConfig.TREASURED_BUILD_ROOT ||
  "https://dlt3m7o57gg8l.cloudfront.net/production/";

export default {
  ...publicRuntimeConfig,
  BUILD_ROOT: buildRoot,
};
