import { useEffect, useState } from "react";

export default function useConfig() {
  const [config, setConfig] = useState(null);

  const getConfig = async () => {
    const response = await fetch("/api/config");
    const responseJSON = await response.json();
    setConfig(responseJSON);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return config;
}
