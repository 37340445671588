import "~/lib/createimagebitmap-polyfill";

import { ReactElement } from "react";
import type { AppProps } from "next/app";
import Router from "next/router";
import { ThemeProvider, generateTheme } from "design-system";
import { AnimateSharedLayout } from "framer-motion";

import { AnalyticsProvider, Analytics } from "~/utils/analytics";
import { useLogRocket } from "~/hooks/useLogRocket";

// Polyfill to fix iOS issue of Object.fromEntries not being available
const customFromEntries = (arr) =>
  arr.reduce((acc, [k, v]) => ((acc[k] = v), acc), {});
Object.fromEntries = Object.fromEntries || customFromEntries;

// Track initial pageview on client-side
if (typeof window !== "undefined") {
  Analytics.page();
}

// Track pageview on route change
Router.events.on("routeChangeComplete", () => {
  Analytics.page();
});

function LogRocketComponent() {
  useLogRocket({});

  return null;
}
const theme = generateTheme({
  styles: {
    global: {
      "html, body, #__next": {
        height: "100%",
        width: "100%",
        overflow: "hidden",
      },
    },
  },
});

export default function App({ Component, pageProps }: AppProps): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <AnalyticsProvider>
        <AnimateSharedLayout>
          {/* Page component is rendered here */}
          <Component {...pageProps} />
        </AnimateSharedLayout>
        <LogRocketComponent />
      </AnalyticsProvider>
    </ThemeProvider>
  );
}
